import { useEffect, useState } from 'react';

export default function useDetectTouch() {
    const [isTouch, setIsTouch] = useState(null);

    useEffect(() => {
        const handleSetIsTouch = () => {
            setIsTouch(
                window.matchMedia('(hover: none) and (pointer: coarse)').matches
            );
        };

        handleSetIsTouch();

        window.addEventListener('resize', handleSetIsTouch);

        return () => {
            window.removeEventListener('resize', handleSetIsTouch);
        };
    }, []);

    return isTouch;
}
