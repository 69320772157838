'use client';

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ReCaptchaLegal from 'components/ui/ReCaptchaLegal';
import Spinner from 'components/ui/Spinner';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import useSubscribe from 'hooks/useSubscribe';
import { EMAIL_REGEX } from 'utils';

import SocialLink from '../SocialLink';

import styles from './FooterSignUp.module.scss';

const Footer = ({ socialMedia, headline, copy }) => {
    const { register, handleSubmit, reset } = useForm();

    const { isSubmitting, isError, isSuccess, subscribe } = useSubscribe(
        'Footer',
        false
    );

    useEffect(() => {
        if (isSuccess) {
            reset({ email_address: null });
        }
    }, [isSuccess, reset]);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <p className={styles.headline}>{headline}</p>
                <Text
                    as="p"
                    className={styles.copy}
                    baseTheme="bodySmall"
                    themes={{
                        large: 'bodyLarge',
                    }}
                >
                    {copy}
                </Text>
            </div>
            <div className={styles.formAndSocial}>
                <form
                    className={cx(styles.form, {
                        [styles.inputSuccess]: isSuccess,
                    })}
                    onSubmit={handleSubmit(subscribe)}
                >
                    <Text
                        as="div"
                        className={styles.inputWrapper}
                        baseTheme="bodyMedium"
                    >
                        <input
                            {...register('email_address', {
                                required: true,
                                pattern: EMAIL_REGEX,
                            })}
                            className={styles.input}
                            type="email"
                            placeholder={
                                isSuccess ? 'Thank You!' : 'Enter your email'
                            }
                        />
                        {isSubmitting && (
                            <div className={styles.spinner}>
                                <Spinner />
                            </div>
                        )}
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className={styles.submitButton}
                        >
                            <SvgIcon type="arrowRight" />
                            <span className="sr-only">Submit</span>
                        </button>
                    </Text>
                    {isError && (
                        <Text
                            as="div"
                            baseTheme="bodyXSmall"
                            className={styles.joinError}
                        >
                            There was an issue subscribing that email address
                        </Text>
                    )}
                    <div className={styles.reCaptchaLegal}>
                        <ReCaptchaLegal />
                    </div>
                </form>
                <div className={styles.socialLinks}>
                    {socialMedia &&
                        socialMedia.length &&
                        socialMedia.map((data, i) => {
                            return (
                                <SocialLink
                                    key={i}
                                    href={data.url}
                                    platform={data.platform}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    headline: PropTypes.string,
    copy: PropTypes.string,
    socialMedia: PropTypes.array,
};

export default Footer;
