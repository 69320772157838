import React from 'react';

import PropTypes from 'prop-types';

import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './SocialLink.module.scss';

const SocialLink = ({ href, platform }) => {
    return (
        <Link className={styles.root} href={href}>
            <SvgIcon className={styles[platform]} type={platform} />
            <span className="sr-only">{platform}</span>
        </Link>
    );
};

SocialLink.propTypes = {
    href: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
};

export default SocialLink;
