'use client';

import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { useRouter } from 'next-nprogress-bar';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './SearchBar.module.scss';

const SearchBar = ({ className, onIsOpen }) => {
    const router = useRouter();

    const [isOpen, setIsOpen] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        router.push(`/search?q=${encodeURIComponent(e.target.search.value)}`);

        setIsOpen(false);
        e.target.search.value = '';
    };

    useEffect(() => {
        onIsOpen && onIsOpen(isOpen);
    }, [onIsOpen, isOpen]);

    return (
        <div
            className={cx(styles.root, className, {
                [styles.isOpen]: isOpen,
            })}
            aria-expanded={isOpen}
            role="button"
            tabIndex={0}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
        >
            <div className={styles.rootInner}>
                <Text
                    as="label"
                    baseTheme="bodyMedium"
                    className={styles.searchIconWrapper}
                >
                    <SvgIcon className={styles.searchIcon} type="search" />
                </Text>
                <aside className={styles.overlay}>
                    <form
                        onSubmit={handleSubmit}
                        className={styles.searchWrapper}
                    >
                        <div className={styles.searchInput}>
                            <Text as="label" baseTheme="bodyMedium">
                                <input
                                    name="search"
                                    autoComplete="off"
                                    type="text"
                                    enterKeyHint="search"
                                    placeholder="Type to search"
                                    className={styles.input}
                                />
                            </Text>
                            <button
                                className={styles.submitButtonWrapper}
                                type="submit"
                            >
                                <div className={styles.submitButtonMobile} />
                                <div className={styles.submitButtonDesktop} />
                                <span className="sr-only">Search</span>
                            </button>
                        </div>
                    </form>
                </aside>
            </div>
        </div>
    );
};

SearchBar.propTypes = {
    className: PropTypes.string,
    onIsOpen: PropTypes.func,
};

export default SearchBar;
