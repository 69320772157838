'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { useMedia } from 'react-use';

import { clamp } from '@madeinhaus/utils';
import cx from 'clsx';
import Image from 'next/image';
import PropTypes from 'prop-types';

import styles from './FooterShreds.module.scss';

const shredsImages = [
    {
        className: styles.shredA,
        src: '/images/footer-shreds/shred-4.webp',
        width: 147,
        height: 245,
    },
    {
        className: styles.shredB,
        src: '/images/footer-shreds/shred-3.webp',
        width: 231,
        height: 301,
    },
    {
        className: styles.shredC,
        src: '/images/footer-shreds/shred-5.webp',
        width: 326,
        height: 422,
    },
    {
        className: styles.shredD,
        src: '/images/footer-shreds/shred-15.webp',
        width: 163,
        height: 243,
    },
    {
        className: styles.shredE,
        src: '/images/footer-shreds/shred-10.webp',
        width: 281,
        height: 193,
    },
    {
        className: styles.shredF,
        src: '/images/footer-shreds/shred-8.webp',
        width: 153,
        height: 329,
    },
    {
        className: styles.shredG,
        src: '/images/footer-shreds/shred-9.webp',
        width: 153,
        height: 329,
    },
    {
        className: styles.shredH,
        src: '/images/footer-shreds/shred-7.webp',
        width: 149,
        height: 231,
    },
    {
        className: styles.shredI,
        src: '/images/footer-shreds/shred-2.webp',
        width: 261,
        height: 335,
    },
    {
        className: styles.shredJ,
        src: '/images/footer-shreds/shred-14.webp',
        width: 235,
        height: 133,
    },
    {
        className: styles.shredK,
        src: '/images/footer-shreds/shred-11.webp',
        width: 231,
        height: 262,
    },
    {
        className: styles.shredL,
        src: '/images/footer-shreds/shred-13.webp',
        width: 317,
        height: 271,
    },
    {
        className: styles.shredM,
        src: '/images/footer-shreds/shred-6.webp',
        width: 265,
        height: 199,
    },
    {
        className: styles.shredN,
        src: '/images/footer-shreds/shred-12.webp',
        width: 376,
        height: 408,
    },
    {
        className: styles.shredO,
        src: '/images/footer-shreds/shred-1.webp',
        width: 205,
        height: 193,
    },
];

const FooterShreds = ({ mobileOpenIndex }) => {
    const ref = useRef(null);

    const [animate, setAnimate] = useState(false);
    const [canAnimate, setCanAnimate] = useState(true);

    const isMobile = useMedia('(max-width: 767px)', true);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: 0,
    });

    useEffect(() => {
        if (!isMobile && intersection?.isIntersecting) {
            setAnimate(true);
        }
    }, [intersection, isMobile]);

    useEffect(() => {
        // skip animation if mobile accordion is moving and triggers intersection
        if (isMobile) {
            setCanAnimate(false);
        }
    }, [isMobile, mobileOpenIndex]);

    useEffect(() => {
        if (isMobile && intersection?.isIntersecting && canAnimate) {
            setAnimate(true);
        }
    }, [intersection, canAnimate, isMobile]);

    useEffect(() => {
        // detect mobile user moving the page
        // scroll listener doesn't work because it triggers when the accordion changes height
        // caveat: mobile without a touchscreen doesn't get the animation

        const handleTouchMove = () => {
            if (intersection?.isIntersecting) return;
            setCanAnimate(true);
        };

        if (isMobile) {
            window.addEventListener('touchmove', handleTouchMove);
        }

        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isMobile, intersection]);

    const handleAnimationEnd = e => {
        if (/toss/g.test(e.animationName)) {
            setAnimate(false);
        }
    };

    const lastScrollTop = useRef(0);
    const lastScrollTime = useRef(Date.now());

    useEffect(() => {
        if (animate) return;

        const handleScroll = () => {
            const now = Date.now();
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            const deltaDistance = scrollTop - lastScrollTop.current;
            const deltaTime = now - lastScrollTime.current;
            const velocity = deltaDistance / deltaTime;
            const velocityThreshold = isMobile ? 1.5 : 3;

            const newVelocity = velocity / velocityThreshold;

            const velocityAdjusted = clamp(newVelocity, 0.3, 1.35);

            ref.current.style.setProperty(
                '--velocity-multiplier',
                velocityAdjusted
            );

            const rotationVelocity = clamp(newVelocity * 3, 1, 40);

            ref.current.style.setProperty(
                '--rotation-multiplier',
                rotationVelocity
            );

            lastScrollTop.current = scrollTop;
            lastScrollTime.current = now;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile, lastScrollTop, lastScrollTime, animate]);

    return (
        <div
            ref={ref}
            className={cx(styles.root, {
                [styles.animate]: animate,
            })}
            aria-hidden
        >
            <div className={styles.inner}>
                {shredsImages.map((shred, i) => {
                    return (
                        <div
                            key={i}
                            className={cx(styles.shred, shred.className)}
                            onAnimationEnd={handleAnimationEnd}
                        >
                            <Image
                                className={styles.shredImage}
                                src={shred.src}
                                alt=""
                                width={shred.width}
                                height={shred.height}
                                loading="lazy"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

FooterShreds.propTypes = {
    mobileOpenIndex: PropTypes.number,
};

export default FooterShreds;
