import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './CheeseburgerButton.module.scss';

const CheeseburgerButton = ({ menuVisible, showIntro, ...rest }) => {
    return (
        <button
            {...rest}
            aria-label="Open the menu"
            className={cx(styles.root, {
                [styles.open]: menuVisible,
                [styles.showIntro]: showIntro,
            })}
        >
            <span role="presentation"></span>
            <span role="presentation"></span>
            <span role="presentation"></span>
            <span role="presentation"></span>
        </button>
    );
};

CheeseburgerButton.propTypes = {
    menuVisible: PropTypes.bool,
    showIntro: PropTypes.bool,
};

export default CheeseburgerButton;
