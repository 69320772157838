import React, { useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { useMeasure } from 'react-use';

import PropTypes from 'prop-types';

import EntryLink from 'components/ui/Link/EntryLink';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './FooterLinkGroup.module.scss';

const FooterLinkGroup = ({
    index,
    headline,
    routes,
    isMobileOpen,
    onClick,
}) => {
    const heightRef = useRef(null);
    const isMobile = useMedia('(max-width: 767px)', true);
    const [widthRef, { width }] = useMeasure();
    const [height, setHeight] = useState(0);

    const mobileHeight = isMobileOpen ? height : 0;
    const mobileTabIndex = isMobileOpen ? 0 : -1;

    useEffect(() => {
        setHeight(heightRef.current.offsetHeight);
    }, [width]);

    return (
        <div ref={widthRef} className={styles.root}>
            <button
                className={styles.header}
                onClick={() => {
                    onClick(index);
                }}
            >
                <Text
                    as="h3"
                    className={styles.headline}
                    baseTheme="labelLarge"
                >
                    {headline}
                </Text>
                <SvgIcon
                    className={styles.headerIcon}
                    type="chevronDownLarge"
                />
            </button>
            <div
                className={styles.list}
                style={{
                    height: isMobile ? mobileHeight : 'auto',
                }}
            >
                <ul className={styles.listInner} ref={heightRef}>
                    {routes.map((route, idx) => {
                        return (
                            <li className={styles.listItem} key={idx}>
                                <Text baseTheme="bodySmall">
                                    <EntryLink
                                        className={styles.link}
                                        entry={route.link}
                                        tabIndex={isMobile ? mobileTabIndex : 0}
                                    >
                                        {route.text}
                                    </EntryLink>
                                </Text>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

FooterLinkGroup.propTypes = {
    index: PropTypes.number.isRequired,
    headline: PropTypes.string.isRequired,
    routes: PropTypes.array.isRequired,
    isMobileOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default FooterLinkGroup;
